.experience-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
  }
  
  .experience {
	display: flex;
	margin: 0.5em;
	padding: 2em;
	text-align: center;
	box-shadow: var(--shadow);
	transition: transform 0.2s linear;
  }
  @media (max-width: 600px) {
	.experience {
	  display: grid;
	}
  }
  .experience:hover {
	transform: translateY(-7px);
  }
  
  .experience__header {
	text-align: left;
	margin:1em;
  }
  
  .experience__description {
	text-align: left;
	margin-top: 1em;
  }
  
  .experience__stack {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 1.2em 0;
  }
  
  .experience__stack-item {
	margin: 0.5em;
	font-weight: 500;
	font-size: 0.8rem;
	color: var(--clr-fg-alt);
  }
  
  .experience .link--icon {
	margin-left: 0.5em;
  }
  